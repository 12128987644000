<template>
  <div class="nf-tabs-nav">
    <div
      v-for="(nav, index) in tabsNav"
      :key="index"
      class="nf-tabs-nav-label"
      :class="{'is-active': currentNav === index}"
      @click="switchNav(index)"
    >
      <i class="icon icon-await"></i>
      <span >{{nav}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['tabsNav'],

  data () {
    return {
      currentNav: 0
    };
  },
  created(){
    const _currentNav = localStorage.getItem("currentNav");
    if (_currentNav){
      this.currentNav = parseInt(_currentNav);
      this.$emit('changeTabsNav', this.currentNav);
      localStorage.removeItem("currentNav");
    } else {
      this.currentNav = 0;
    }
  },
  methods: {
    switchNav (index) {
      this.currentNav = index;
      localStorage.setItem("currentNav", index);
      this.$emit('changeTabsNav', index);
    }
  }
};
</script>
<style lang="less" scoped>
.nf-tabs-nav{
  background:#ffffff;
  box-shadow: 0 2px 3px 0px rgba(38,90,225,0.15);

  &-label{
    display: inline-block;
    padding: 16px 32px;
    font-size: 16px;
    color: #333333;
    position: relative;
    cursor: pointer;

    &.is-active{
      color: #3380FE;
      border-bottom: 3px solid #3380FE;

      &::before{
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #3380FE;
        box-shadow: 0 2px 3px 0px rgba(51, 128, 254, .7);
      }
    }
  }
}
</style>
