<template>
  <div class="device">
      <div class="operate">
        <span>一共有{{page.total}}条检测报告</span>
      </div>
      <el-table
        ref="multipleTable"
        stripe
        :data="tableList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column prop="documentTitle" header-align="center" label="姓名"> -->
        <el-table-column prop="specimenCode" align="center" label="样品编号">
        </el-table-column>
        <el-table-column prop="reportDate" label="出报告日期" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.reportDate==null?'-':dateToDate(scope.row.reportDate)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkUser" label="检测人" align="center">
        </el-table-column>
        <el-table-column fixed="right" align="center" label="导出报告">
          <template slot-scope="scope">
            <span class="edit-box" @click="exporMeth(scope.row.id)">
              <i class="icon icon-export"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="page.total > 8"
        @pageChange="pageChange"
        :total="page.total"
        :currentPage="page.currentPage"
        :pageSize="page.pageSize"
      ></pagination>
    </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import { downlodFile, formateDate, downloadImg, use_iframe_download} from '@/utils/utils';

export default {
  components: {
    pagination
  },
  data(){
    return {
      tableList: [],
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 8
      },
      param: {
        pageNum: 1,
        pageSize: 8
      },
      imgPath: ''
    };
  },
  created(){
    this.getList();
  },
  methods: {
    dateToDate(date){
      return formateDate(date);
    },
    //获取列表
    async getList() {
      const api = this.$fetchApi.examiningListReq.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.tableList = data.result.rows;
        this.page.total = data.result.total;
      }
    },
     // 分页器
    pageChange(item) {
      this.page.currentPage = item.page_index;
      this.page.pageSize = item.page_limit;
      
      this.param.pageNum = item.page_index;
      this.param.pageSize = item.page_limit;
      this.getList();
    },
    //导出
    async exporMeth(id){
      const api = this.$fetchApi.downloadWord.api + id;
      const _params={ id: id };
      
      const data = await this.$fetchData.fetchGet(_params, api);
      downlodFile(data, '检测报告.doc');


      // const api = this.$fetchApi.downloadWord.api + id;
      // const _params={ id };
      
      // const data = await this.$fetchData.fetchGet(_params, api);
      // if (data.code === '200'){
      //   if (data.result.checkReportId === null || data.result.checkReportId === '') return;
      //   const fileName = data.result.checkReportId[0].name;
      //   const filePath = data.result.checkReportId[0].filePath;
      //   downloadImg(filePath, '检测报告');
      //   //文件下载
      //   // downloadImg(filePath, fileName);
      // }
      // console.log(data);
    }
  }
};
</script>

<style lang="less" scoped>
.device{
  .operate {
    padding: 30px 0px 30px 30px;
    span{
      display: inline-block;
      background-color: #fbebea;
      width: 100%;
      line-height: 28px;
      font-size: 14px;
      padding-left: 15px;
      color: #F13D2D;
    }
  }
  .edit-box{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    .icon {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 20px;
      height: 20px;
      background: none no-repeat center;
      background-size: 100%;
      margin-right: 4px;

      &-export {
        background-image: url("../../../assets/images/laboratoryInfo/icon_dc.png");
      }
    }
  }
  
}
</style>