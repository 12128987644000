<template>
  <div class="personnel">

    <div class="per-table" v-if="isVisible===false">
      <div class="operate">
        <el-button type="primary" size="small" @click="add" icon="el-icon-document-add">新增人员</el-button>
      </div>
      <el-table
        ref="multipleTable"
        stripe
        :data="tableList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column prop="documentTitle" header-align="center" label="姓名"> -->
        <el-table-column prop="userName" align="center" label="姓名"  width="240px">
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center"  width="240px">
        </el-table-column>
        <el-table-column prop="position" label="岗位" align="center">
        </el-table-column>
      </el-table>
      <pagination
        v-if="page.total > 8"
        @pageChange="pageChange"
        :total="page.total"
        :currentPage="page.currentPage"
        :pageSize="page.pageSize"
      ></pagination>
    </div>

    <div v-if="isVisible">
      <div class="closeBtn"  @click="handClose"><i class="el-icon-arrow-left"></i></div>
      <!-- 头部标题 -->
      <div class="title-panel">
        <i class="tlt-icon"></i>
        <span>新增人员</span>
      </div>
      <el-form class="formTab" label-position="right" label-width="100px" ref='formTabel' :rules="rules" :model="formTabel">
        <el-form-item label="姓名：" prop="userName">
          <el-input class="tabInput" size="small" v-model="formTabel.userName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input class="tabInput" size="small" maxlength="11" v-model="formTabel.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="岗位：" prop="position">
          <el-input class="tabInput" size="small" v-model="formTabel.position" placeholder="请输入岗位"></el-input>
        </el-form-item>
        <el-form-item class="protBtn">
          <div class="sub-btn" @click="submitForm('formTabel')">提交</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';

export default {
  components: {
    pagination
  },
  data(){
    return {
      tableList: [],
      params: {
        pageNum: 1,
        pageSize: 8
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 8
      },
      isVisible: false, //新增人员
      formTabel: {
        userName: '',
        phone: '',
        position: ''
      },
      rules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }
        ],
        position: [{ required: true, message: '请输入岗位', trigger: 'blur' }]
      }
    };
  },
  created(){
    this.getList();
  },
  methods: {
    //提交新增人员
    add(){
      this.isVisible = true;
      localStorage.setItem("currentNav", 0);
      this.$emit('handClickBtn', false);
    },
    handClose(){
      this.isVisible = false;
      this.$emit('handClickBtn', true);
    },
    async getList() {
      const api = this.$fetchApi.personnelList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200') {
        this.tableList = data.result.rows;
        this.page.total = data.result.total;
      }
    },
     // 分页器
    pageChange(item) {
      this.page.currentPage = item.page_index;
      this.page.pageSize = item.page_limit;
      
      this.params.pageNum = item.page_index;
      this.params.pageSize = item.page_limit;
      this.getList();
    },
    async submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.addPersonnel(formName);
        }
      });
    },
    async addPersonnel(formName){
      const api = this.$fetchApi.addPersonnel.api;
      const data = await this.$fetchData.fetchPost(this.formTabel, api, 'json');
      if (data.code === '200') {
        this.getList();
        this.handClose();
        this.resetForm(formName);

      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.personnel{
  position: relative;
  .operate {
    padding: 15px;
  }

  .closeBtn{
    position: absolute;
    top: 5px;
    left: 0px;
    // right: 35%;
    font-size: 22px;
    color: #999999;
    cursor: pointer;
    &:hover{
      color: #3387FD;
    }
  }

  .title-panel{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 20px;
    line-height: 30px;
    font-size: 17px;
    .tlt-icon{
      width: 10px;
      height: 10px;
      background-color: #4380fe;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .formTab{
    width: 500px;
    padding-left: 50px;
    display: inline-block;
  }

  .protBtn{
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 40px;
      .sub-btn{
        width: 150px;
        line-height: 35px;
        color: #ffffff;
        background-color:#3387FD;
        border-radius: 5px;
        text-align: center;
      }
      /deep/ .el-form-item__content{
        margin-left: 20px!important;
      }
    }
  .tabInput{
    width: 200px;
  }
}
</style>
