<template>
  <div class="content clearfix">
    <div class="nf-tabs" v-if="isTabsNav">
      <tab-nav :tabsNav="tabsNav" @changeTabsNav="switchNav"></tab-nav>
    </div>

    <personnel v-if="currentTabsNav === 0" @handClickBtn="handClickBtn"></personnel>
    <deviceTab v-if="currentTabsNav === 1" @handClickBtn="handClickBtn"></deviceTab>
    <testReport v-if="currentTabsNav === 2"></testReport>
  </div>
</template>
<script>
import { Table, Select, MessageBox } from 'element-ui';
import TabNav from './component/tab-nav';
import personnel from '@/pages/laboratoryInfo/resourcesTable/personnel.vue';
import device from '@/pages/laboratoryInfo/resourcesTable/device.vue';
import testReport from '@/pages/laboratoryInfo/resourcesTable/testReport.vue';

export default {
  components: {
    TabNav,
    personnel,
    'deviceTab': device,
    testReport
  },
  created() {
    this.isTabsNav = true;
  },
  data() {
    return {
      isTabsNav: true,
      currentTabsNav: 0,
      tabsNav: ['人员', '设备', '检测报告']
    };
  },
  methods: {
    switchNav(data) {
      console.log(data);
      this.currentTabsNav = data;
    },
    handClickBtn(val){
      this.isTabsNav = val;
    }
  }
};
</script>
<style lang="less" scoped>
.operate {
  padding: 20px;
}

.table-cont-title {
  cursor: pointer;
}
.el-select {
  margin-bottom: 10px;
}
</style>